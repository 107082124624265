/***********************/
/*                     */
/*   Custom Widgets    */
/*                     */
/***********************/


/***********************/
/*     Chart Simle    */
/***********************/

.widget-chart-simple{

  .widget-body{
    padding-top: 10px;
  }
  .chart-value{
    font-size: 24px;
  }

  .chart{
    margin-top: 20px;
  }
}

.jqstooltip{
  @include box-sizing(content-box);
}

/***********************/
/*    Chart Changes    */
/***********************/

.widget-chart-changes{
  .chart{
    margin: -$widget-padding-vertical (-$widget-padding-horizontal) 0;
    padding: $widget-padding-vertical 0 0;
  }

  .chart-title{
    margin: $line-height-computed 0 0;
  }

  .chart-value, .chart-value-change{
    padding: 0 $widget-padding-horizontal;
  }

  .chart-value{
    margin-bottom: 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-h4 + 3;
    line-height: 1;
    color: $white;
  }

  .chart-value-change{
    color: rgba($white, .7);
    font-size: $font-size-smaller;
  }
}

/***********************/
/*  Chart Stats Simple */
/***********************/

.widget-chart-stats-simple{
  > header{
    h1,h2,h3,h4,h5,h6 {
      margin-bottom: $line-height-computed/4;
    }
  }

  .chart{
    height: 200px;
    margin: $line-height-computed/2 (-$widget-padding-horizontal) (-$widget-padding-vertical);
    > .chart-inner{
      height: 100%;
    }
  }
}

/***********************/
/*      Big Chart      */
/***********************/

.widget-big-chart{
  .chart{
    height: 200px;
  }
}

/***********************/
/*  Part:Chart Stats   */
/***********************/

.chart-stats{
  border-top: 1px solid $gray-lighter;
}

/***********************/
/* Part:Progress Stats */
/***********************/

.progress-stats{
  .name{
    margin-bottom: 2px;
  }
  .description{
    margin-bottom: 2px;
  }
  .status{
    margin-top: 15px;
  }

  @media (max-width: $screen-md-max) {
    .description + .progress{
      margin-bottom: 0;
    }
  }
}

/***********************/
/*    Part:Stats Row   */
/***********************/

.stats-row{
  margin-bottom: 15px;
}

.stat-item{
  display: inline-block;
  padding-right: 15px;

  & + .stat-item{
    padding-left: 15px;
    border-left: 1px solid $hr-border;
  }
  .name{
    margin-bottom: 2px;
  }
  .value{
    font-weight: bold;
  }

  &.stat-item-mini-chart{
    position: relative;
    top: -12px;
    padding-left: 0;
    border-left: none;
  }
}


/***********************/
/*  Part:Map Controls  */
/***********************/

.map-controls{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #bbb;
  background-color: $gray-semi-lighter;
  border-bottom-left-radius: $border-radius-base;
  border-bottom-right-radius: $border-radius-base;

  > li > a{
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;

    color: $gray;
    &:hover{
      background-color: $gray-lighter;
      color: $gray
    }
  }

  > li.active > a{
    &, &:hover{
      background-color: $white;
      color: $gray;
      font-weight: $font-weight-bold;
    }
  }

  > li:first-child > a{
    border-bottom-left-radius: $border-radius-base;
  }

  > li:last-child > a{
    border-bottom-right-radius: $border-radius-base;
  }
}

/***********************************/
/**          NEWS LIST           **/
/**********************************/

.news-list{
  margin-bottom: 0;
  padding-left: 0;

  li{
    list-style: none;
    @include box-sizing(content-box);
    border-top: 1px solid $gray-lighter;
    padding: 12px;
    cursor: pointer;
    @include transition(background-color .2s ease-out);

    &:hover{
      background: $body-bg-light;
    }

    &:last-child{
      margin-bottom: -10px;
    }
  }

  img,
  .icon{
    float: left;
    height: 50px;
    width: 50px;
  }

  .icon{
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 28px;
  }

  .news-item-info{
    margin-left: 62px; /*50 + 12px padding*/
  }

  .name{
    text-transform: uppercase;

    a{
      text-decoration: none;
      &:hover{
        color: $link-color;
      }
    }
  }
}


/***********************************/
/**      LIST GROUP SORTABLE      **/
/***********************************/

.list-group-sortable{
  > .list-group-item{
    margin-bottom: 0;
    border-radius: $border-radius-base;

    + .list-group-item{
      margin-top: $line-height-computed/2;
    }
  }

  > .list-group-item-placeholder{
    border: 1px dashed $gray-light;
    background-color: $gray-semi-lighter;
  }

  &:last-of-type > .list-group-item:last-child{
    border-bottom: 1px solid $list-group-border;
  }
}

/***********************************/
/**           ICON LIST           **/
/***********************************/

.icon-list{
  margin-top: $line-height-computed;
}

.icon-list-item{
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  > a{
    color: $text-color;
    text-decoration: none;
  }


  .glyphicon,
  .fa{
    width: 32px;
    margin-right: 10px;
  }

  &:hover{
    .glyphicon,
    .fa{
      font-size: 28px;
    }

    .fa{
      vertical-align: -5px;
    }

    .glyphicon{
      vertical-align: -6px;
    }
  }
}

/***********************************/
/**            INVOICE            **/
/***********************************/

.widget-invoice{
  padding: 40px;

  > header{
    .invoice-logo{
      max-height: 50px;
    }

    + .widget-body{
      margin-top: 70px;
    }
  }
}

/***********************************/
/**             LOGIN             **/
/***********************************/

.login-page{
  background-color: $gray-semi-lighter;
}

.login-page .page-footer {
  margin-bottom: 30px;
  font-size: $font-size-mini;
  color: $text-muted;
  text-align: center;
  @media (min-height: 600px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.widget-login-container{
  padding-top: 10%;
}

.widget-login-logo{
  margin-bottom: 15px;
  text-align: center;
  font-weight: $font-weight-normal;
  .fa-circle{
    font-size: 13px;
    margin: 0 20px;
  }
}

.widget-login{
  padding: 30px;

  > header{
    h1,h2,h3,h4,h5,h6 {
      font-weight: $font-weight-normal;
    }
  }
}

.widget-login-info{
  font-size: $font-size-mini;
  color: #888;
  margin-top: 1px;
  margin-bottom: 0;
}

.login-form{
  .form-control{
    font-size: $font-size-mini;
    border: none;
    background-color: $gray-lighter;
    &:focus{
      background-color: $gray-semi-lighter;
    }
  }
}

/***********************************/
/**        Full Calendar          **/
/***********************************/

.full-calendar{
  margin-top: 10px;
}

.calendar-controls{
  .btn{
    font-size: $font-size-mini;
  }
}

.calendar-external-events{
  margin-top: 20px;

  .external-event{
    margin: 10px 0;
    padding: 6px;
    font-size: $font-size-mini;
    cursor: pointer;
    border-radius: $border-radius-base;
    background-color: $white;
    border: 1px solid #bbb;
  }
}

.widget-calendar{
  @media (min-width: $screen-lg-min) {
    margin-top: -100px;
  }
}

/***********************************/
/**          Post Links           **/
/***********************************/

.post-links{
  margin-bottom: 0;
  font-size: $font-size-small;
  padding-left: 0;
  @include clearfix();
  > li{
    float: left;
    list-style: none;

    + li{
      &:before{
        color: #999;
        content: "\25cf";
        padding: 0 8px;
      }
    }
    > a{
      text-decoration: none;
      color: $text-muted;
      &:hover{
        color: $text-muted;
      }
    }
  }

  &.no-separator > li + li{
    margin-left: 12px;
    &:before{
      content: normal;
    }
  }
}

/***********************************/
/**          Post Comments           **/
/***********************************/

.post-comments{
  font-size: $font-size-small;
  padding-left: 0;
  @include clearfix();

  .post-links + &{
    margin-top: $line-height-computed / 2;
  }

  > li {
    padding: 10px;
    border-top: 1px solid #e7e7e7;
    list-style: none;
    @include clearfix();

    &:last-child{
      padding-bottom: 0;
    }
  }

  p:last-child{
    margin-bottom: 0;
  }

  .avatar{
    margin-top: 1px;
  }

  .author{
    margin-top: 0;
    margin-bottom: 2px;
    color: #7ca9dd;
  }

  .comment-body{
    overflow: auto;
  }

  h6.author > small{
    font-size: 11px;
  }

  .widget > footer &{
    margin-left: -$widget-padding-horizontal;
    margin-right: -$widget-padding-horizontal;
  }
}

/***********************************/
/**           Post User           **/
/***********************************/

.post-user{
  position: relative;
  @include clearfix();

  img{
    border: 3px solid white;
  }
}


/***********************************/
/**            Gallery            **/
/***********************************/

.gallery{
  .thumbnail{

    a{
      display: inline-block;
      overflow: hidden;
      max-width: 100%;
      > img{
        @include transition-transform(.15s ease);
      }
    }
    &:hover{
      a > img{
        @include scale(1.1, 1.1);
      }
    }
  }

  .post-links > li > a{
    display: inline;
  }

  > div{
    max-width: 100%;
  }
}

/***********************************/
/**         Search Result         **/
/***********************************/

.search-result-categories{
  > li > a {
    color: $gray-semi-light;
    font-weight: $font-weight-normal;
    &:hover{
      background-color: $gray-semi-lighter;
      color: $text-color;
    }

    > .glyphicon{
      margin-right: 5px;
    }

    > .badge{
      float: right;
    }
  }
}

.search-results-count{
  margin-top: 10px;
}

.search-result-item{
  padding: 20px;
  background-color: $white;
  border-radius: $border-radius-base;
  @include clearfix();

  .image-link{
    display: block;
    overflow: hidden;
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin: -20px 0 -20px -20px;
      float: left;
      width: 200px;
    }
    @media (max-width: $screen-xs-max) {
      max-height: 200px;
    }
  }

  .image{
    max-width: 100%;
  }

  .info{
    margin-top: 2px;
    font-size: $font-size-small;
    color: $text-muted;
  }

  .description{
    font-size: $font-size-mini;
  }

  + .search-result-item{
    margin-top: 20px;
  }
}

.search-result-item-body{
  @media (min-width: $screen-sm-min) {
    margin-left: 200px;
  }
}

.search-result-item-heading{
  font-weight: $font-weight-normal;

  > a {
    color: $text-color;
  }

  @media (min-width: $screen-sm-min) {
    margin: 0;
  }
}

/***********************************/
/**           Profile             **/
/***********************************/

.post-user-profile {
  margin-top: -75px;

  .contacts {
    display: block;
    margin-top: 25px;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 0;
    text-align: center;

    > li {
      display: inline-block;
      line-height: 2.2;
      list-style: none;
      text-align: left;
      margin: 0 10px;

      @media (min-width: $screen-md-min) {
        width: 150px;
        white-space: nowrap;
      }

      > a {
        color: lighten($text-color, 30%);
        text-decoration: none;

        &:hover,
        &:focus {
          color: $text-color;
        }
      }
    }

    .fa {
      font-size: $font-size-large;
      vertical-align: middle;
    }
  }
}

.stats-row-profile {
  .stat-item {
    border-left: 0;
    padding-left: 15px;
    text-align: center;

    @media (min-width: $screen-md-min) {
      padding-right: 0;
    }

    .value {
      font-size: 28px;
      font-weight: $font-weight-base;
    }
  }
}

.activities{
  margin-top: -20px;
  h2 {
    margin-left: 20px
  }
}
.event{
  .activities &{
    margin-top: $line-height-computed;
    width: 100%;
  }
}
/***********************************/
/**           Time Line           **/
/***********************************/

.timeline{
  position: relative;
  min-height: 100%;
  list-style: none;
  padding-left: 0;
  margin-bottom: -40px; /* content padding bottom */
  padding-bottom: 80px;

  > li {
    @include clearfix();

    + li{
      margin-top: 30px;
    }
  }

  /* the time line :) */
  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 24%;
    width: 8px;
    content: " ";
    margin-left: -4px;
    background-color: $white;

    @media (min-width: $screen-md-min) {
      left: 50%;
      margin-left: -4px;
    }
  }

}

.event{
  background: $white;
  border-radius: $border-radius-base;
  padding: 20px 20px 0;
  position: relative;

  .timeline & {
    float: right;
    width: 68%;

    &:before{
      right: 100%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      border: 10px solid rgba(0,0,0,0);
      border-right-color: $white;
      top: 15px;
    }
  }

  .post-comments{
    margin-left: -20px;
    margin-right: -20px;
  }

  > footer{
    margin: 20px -20px 0;
    padding: 10px 20px;
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
    background-color: #f3f3f3;

    @include clearfix();

    .thumb{
      margin-left: 10px;
    }
  }

  @media (min-width: $screen-md-min) {
    .timeline & {
      width: 45%;
    }

    .timeline > li.on-left &{
      float: left;
      &:before{
        right: auto;
        left: 100%;
        border-right-color: rgba(0,0,0,0);
        border-left-color: $white;
      }
    }
  }
}

.event-time{
  .timeline &{
    float: left;
    width: 18%;
    margin-top: 5px;
    text-align: right;

    > .date{
      display: block;
      font-size: $font-size-larger;
    }

    > .time{
      display: block;
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
    }
  }

  @media (min-width: $screen-md-min) {
    .timeline &{
      width: 46%;
    }

    .timeline > li.on-left &{

      float: right;
      text-align: left;
    }
  }
}

.event-icon{
  .timeline &{
    position: absolute;
    left: 24%;
    width: 50px;
    height: 50px;
    line-height: 37px;
    margin-left: -25px;
    background-color: $white;
    border: 7px solid $white;
    border-radius: 50%;
    text-align: center;

    &.event-icon-danger{ background-color: $brand-danger; border-color: lighten($brand-danger, 7%)}
    &.event-icon-warning{ background-color: $brand-warning; border-color: lighten($brand-warning, 7%)}
    &.event-icon-success{ background-color: $brand-success; border-color: lighten($brand-success, 7%)}
    &.event-icon-info{ background-color: $brand-info; border-color: lighten($brand-info, 7%)}
    &.event-icon-primary{ background-color: $brand-primary; border-color: lighten($brand-primary, 7%)}

    &.event-icon-danger,
    &.event-icon-warning,
    &.event-icon-success,
    &.event-icon-info,
    &.event-icon-primary{
      color: $white;
    }

    @media (min-width: $screen-md-min) {
      left: 50%;
    }

    > img{
      width: 36px;
      height: 36px;
      margin-top: -4px;
    }
  }
}

.event-heading{
  margin: 0 0 2px;
  font-weight: $font-weight-semi-bold;
  > a {
    text-decoration: none;
    color: #7ca9dd;
  }
  > small{
    font-weight: $font-weight-semi-bold;
    > a{
      text-decoration: none;
      color: $text-muted;
    }
  }
}

.event-map{
  height: 200px;
  margin: 0 -20px -20px;
  overflow: visible !important;
}

.event-image{
  margin: 0 -20px -20px;
  max-height: 260px;
  overflow: hidden;
  > img{
    max-width: 100%;
  }
}

/***********************************/
/**          ERROR PAGE           **/
/***********************************/

.error-page{
  background-color: $gray-semi-lighter;
}

.error-container{
  padding-top: 5%;
  text-align: center;

  > .btn{
    padding-left: 35px;
    padding-right: 35px;
  }
}

.error-code{
  font-size: 80px;
  font-weight: $font-weight-normal;
  color: $gray-dark;
  @media (min-width: $screen-sm-min) {
    font-size: 180px;
  }
}

.error-info{
  font-size: 20px;
  color: $gray-dark;
}

.error-help{
  font-size: 14px;
}

.error-page .page-footer {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  width: 100%;
  font-size: $font-size-mini;
  color: $text-muted;
  text-align: center;
}

/***********************************/
/**             EMAIL             **/
/***********************************/


.nav-email-folders{
  > li > a {
    color: darken($gray-semi-light, 20%);
    font-weight: $font-weight-normal;

    &:hover,
    &:focus{
      background-color: #e5e5e5;
      color: $text-color;
    }

    > .glyphicon{
      margin-right: 5px;
    }

    > .fa-circle{
      margin-top: 3px;
    }

    > .badge{
      background-color: $brand-danger;
    }
  }

  > li.active > a{
    &,
    &:hover,
    &:focus {
      background-color: $gray-semi-lighter;
      color: $text-color;
      font-weight: $font-weight-semi-bold;

      > .badge{
        color: $text-color;
      }
    }
  }
}

.widget-email-count{
  display: inline-block;
  margin: 0;
  font-size: $font-size-mini;
  color: $text-muted;
  line-height: 29px;

  + .widget-email-pagination{
    margin-left: 10px;
    border-left: 1px solid $gray-semi-lighter;
    padding-left: 15px;
    border-radius: 0;
    vertical-align: -9px;
  }
}

.widget-email-pagination{
  margin: 0;
}

.table-emails{
  margin-bottom: 0;

  .name,
  .subject,
  .date{
    cursor: pointer;
  }

  .date{
    text-align: right;
    min-width: 65px;
  }

  .unread{
    font-weight: $font-weight-semi-bold;
    color: $text-color;
  }

  .starred{
    color: $text-muted;
    cursor: pointer;
    @include transition(color .2s);
    &:hover{
      color: $text-color;
    }

    .fa-star{
      color: $brand-warning;
    }
  }
}

.email-view{
  hr{
    margin: 5px 0;
  }

  .email-body{
    margin-top: $line-height-computed;
  }
}

.email-details img{
  width: 30px;
  height: 30px;
  float: left;
}

.email-details-content{
  @include clearfix();

  .email{
    color: $text-muted;
    font-size: $font-size-mini;
  }

  .receiver{
    display: block;
    color: $text-muted;
    margin-top: -6px;
  }

  .email-date{
    margin-right: 10px;
    line-height: 24px;
    vertical-align: middle;
  }
}

.email-attachments{
  .attachment{
    img{
      display: block;
    }

    .title{
      margin: 0;
      font-weight: bold;
    }

  }
}

/***********************************/
/**           Weather             **/
/***********************************/


.widget-image .forecast{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

/***********************************/
/**             Tags              **/
/***********************************/

.tags{
  padding-left: 0;
  list-style: none;
  @include clearfix();

  > li{
    float: left;

    > a{
      padding: 2px 8px;
      font-size: $font-size-mini;
      border-radius: 6px;
      border: 1px solid white;
      color: inherit;

      text-decoration: none;

      &:hover{
        background-color: rgba(0,0,0,.1);

      }

      .fa{
        font-size: 8px;
        vertical-align: 2px;
        margin-right: 3px;
        opacity: .8;
      }
    }

    + li > a{
      margin-left: 6px;
    }
  }

  .widget-top-overflow > img + &{
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 20px;
  }
}

/***********************************/
/**       Chat List Group         **/
/***********************************/

.widget-chat-list-group{
  padding-top: $line-height-computed/2;
  .list-group-item{
    margin-left: $widget-padding-horizontal;
    margin-right: $widget-padding-horizontal;
    padding: 0;
    border: 0;

    & + .list-group-item{
      margin-top: 30px;
    }

    .thumb,
    .thumb-sm{
      float: left;
      margin-right: 15px;
    }

    .time{
      float: right;
      font-size: $font-size-small;
      color: $link-color;
    }

    .sender{
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: $font-size-mini;
      font-weight: $font-weight-normal;
    }

    .body{
      font-size: $font-size-mini;
      color: $text-muted;
    }

    &.on-right{
      .thumb,
      .thumb-sm{
        float: right;
        margin-left: 15px;
        margin-right: 0;
      }

      .time{
        float: left;
      }

      .sender{
        text-align: right;
      }
    }
  }
}

/***********************************/
/**          Components           **/
/***********************************/
.alert-dismissible .close {
  right: 0px;
  }
